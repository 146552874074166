import initialState from '../../states/cash_register';
$ = require('jquery');
$.fn.tab = require('semantic-ui-tab');

export default function( preserveState = {} ) {
  $( 'a[data-tab="second"]' ).removeClass( 'active' );
  $( 'a[data-tab="first"]' ).addClass( 'active' );
  $( '#tab-container' ).tab( 'change tab', 'first' );

  // $( '.tab' ).tab( 'change tab', 'first' );
  const { crid, uid, last_transactions, headquarters } = this;
  const theOrigin = initialState();
  const theNewState = {
    ...theOrigin,
    ...preserveState,
    crid, uid, headquarters, last_transactions
  };
  Object.assign( this.$data, theNewState );
}
