import AutoNumeric from 'autonumeric';

export default function( uid ) {
  this.loading = true;
  const defaultConfig = {
    confirmButtonText: 'Siguiente',
    cancelButtonText: 'Cancelar',
    showCancelButton: true,
    allowOutsideClick: false
  };


  $.get( `/cash_registers/${ this.uid }/novelties/types` ).done( ( response ) => {

    const types = response.map( ob => ( `<option value="${ ob.idnovelty_type }">${ ob.novelty_type }</option>` ) );
    setTimeout( () => {

      new AutoNumeric( '.swal-autonumeric', AutoNumeric.getPredefinedOptions().integerPos );
    }, 1000 );
    Swal.fire( {
      ...defaultConfig,
      showLoaderOnConfirm: true,
      title: 'Crear novedad',
      html: ( `
      <select id="document_type" name="novelty[type]" class="swal2-input swal2-select">
        <option value="" disabled="">Selecciona tipo de novedad</option>
        ${ types.join( '' ) }
      </select>
      <input type="text" name="novelty[amount]" id="novelty_amount" placeholder="Valor" class="swal2-input swal-autonumeric">
      <textarea id="novelty_description" name="novelty[description]" placeholder="Descripción" class="swal2-textarea"></textarea>
    ` ),
      preConfirm: () => {
        return [
          document.getElementById( 'document_type' ).value,
          document.getElementById( 'novelty_amount' ).value,
          document.getElementById( 'novelty_description' ).value
        ];
      }
    } ).then( ( { value: userForm, dismiss } ) => {
      if( dismiss==='cancel' ) return false;

      const novelty = {
        amount: userForm[1].replace(/[^\w\s]/gi, ''),
        novelty_type_id: userForm[0],
        description: userForm[2]
      };

      $.ajaxSetup({
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      });

      $.post( `/cash_registers/${ this.uid }/novelties`, { novelty } ).done( response => {
          toastr['success']( response.message );
        }
      ).fail( ( { responseJSON: error } ) => {
        Swal.fire( {
          type: 'error',
          title: error.error,
          html: ( `
                <ul style="text-align: left">
                  <li>${ error.list.join( '</li><li>' ) }</li>                        
                </ul>
              ` )
        } );
      } ).always( () => {
        this.loading = false;
      } );

    } );
  } ).always( () => {
    this.loading = false;
  } );
}
