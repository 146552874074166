export default function (event) {
  const procedure = event.target.value;

  if (procedure) {
    $.ajax({ url: `/procedures/${this.uid}/fares/${procedure}.json` })
      .done((data) => {
        this.subtotal = parseInt(data.fares.vlr_a_cobrar);
        this.procedure_name = data.procedure;

        if (this.payMethod === 'Datáfono') this.posTerminalPay = data.fares.vlr_a_cobrar;
        if (this.payMethod === 'Transferencia') this.transferPay = data.fares.vlr_a_cobrar;
      })
      .always(() => {
        this.loading = false;
      })
    ;
  } else {
    this.subtotal = 0;
    this.procedure_name = '';
  }
}
