$ = require('jquery')
$.fn.dropdown = require('semantic-ui-dropdown')

export default function() {
  this.loading = true;
  const defaultConfig = {
    confirmButtonText: 'Siguiente',
    cancelButtonText: 'Cancelar',
    showCancelButton: true,
    allowOutsideClick: false
  };
  Swal.fire( {
    ...defaultConfig,
    title: 'Ingrese el número de documento del cliente',
    input: 'text',
    inputAttributes: { autocapitalize: 'off' },
    showLoaderOnConfirm: true,
    preConfirm: ( document ) => {
      return fetch( `/students/${ this.uid }/search?doc=${ document }` )
        .then( response => {
          return response.json();
        } );
    }
  } ).then( ( { value, dismiss } ) => {

    if( dismiss==='cancel' ) {
      this.loading = false;
      return false;
    }

    if( value.name ) {
      Swal.fire({
        ...defaultConfig,
        title: `${ value.name } ${ value.last_name }`,
        text: `${ value.document_type }. ${ value.document }`
      }).then(({ value: confirmation }) => {
        this.loading = false;
        if( confirmation ) this.student = value;
      });
      $.get( `/cash_registers/${ this.uid }/cashiers/${value.idstudent}/student_discount`, { document: value.document } ).then((data) => {
        this.student_discount = data.student_discount
        this.discount_temporal = data.student_discount.discount
      });
    } else {
      Swal.fire( {
        ...defaultConfig,
        showLoaderOnConfirm: true,
        title: 'Nuevo cliente',
        html: ( `
                  <input type="text" id="name" name="student[name]" placeholder="Nombre" class="swal2-input">
                  <input type="text" id="last_name" name="student[last_name]" placeholder="Apellido" class="swal2-input">
                  <select id="document_type" name="student[document_type]" class="swal2-input swal2-select">
                    <option value="" disabled="">Selecciona tipo de documento</option>
                    <option value="CC">Cédula de ciudadanía</option>
                    <option value="CE">Cédula de extranjería</option>
                    <option value="PAS">Pasaporte</option>
                    <option value="TI">Tarjeta de identidad</option>
                  </select>
                  <input type="text" id="document" name="student[document]" placeholder="Número de documento" class="swal2-input" value="${ value.document }">
                ` ),
        preConfirm: () => {
          return [
            document.getElementById( 'name' ).value,
            document.getElementById( 'last_name' ).value,
            document.getElementById( 'document_type' ).value,
            document.getElementById( 'document' ).value
          ];
        }
      } ).then( ( { value: userForm, dismiss } ) => {

        if( dismiss==='cancel' ) {
          this.loading = false;
          return false;
        }

        this.student.name = userForm[0];
        this.student.last_name = userForm[1];
        this.student.document_type = userForm[2];
        this.student.document = userForm[3];

        $.ajaxSetup({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          }
        });

        $.post( `/cash_registers/${ this.uid }/students`, { student: this.student } )
          .done( response => {
            toastr['success']( response.message );
            this.student.idstudent = response.student;
            $.get(`/cash_registers/${ this.uid }/cashiers/${this.student.idstudent}/student_discount`, {document: this.student.document}).then((data) => {
              this.student_discount = data.student_discount
              this.discount_temporal = data.student_discount.discount
            });
          })
          .fail( ( { responseJSON: error } ) => {
            try {
              this.student = initialState.student;
              Swal.fire( {
                type: 'error',
                title: error.error,
                html: ( `
                <ul>
                  <li>${ error.list.join( '</li><li>' ) }</li>
                </ul>
              ` )
              } );
            } catch(e) {
              Swal.fire( {
                type: 'error',
                title: 'Error inesperado',
                text: 'Por favor contacte a su administrador.'
              } );
            }
          } )
          .always( () => {
            this.loading = false;
          });
      });

    }
  } );

}
