export default function () {
  this.loading = true;

  const { positive_balance, cash, noncash, transfer, credit, payMethod, discount, payment } = this.invoices;
  const course = this.student.courses[this.selectedCourse];
  const invoice_id = course.invoice;

  let fk_payment_method;
  switch (payMethod) {
    case 'Efectivo':
      fk_payment_method = 1;
      break;
    case 'Datáfono':
      fk_payment_method = 2;
      break;
    case 'Transferencia':
      fk_payment_method = 9999;
      break;
    case 'Crédito':
      fk_payment_method = 9998;
      break;
    case 'Mixto':
      fk_payment_method = 6;
      break;
    default:
      fk_payment_method = 7;
  }

  $.ajaxSetup({
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    }
  });


  $.post(`/payments/${this.uid}/${invoice_id}`, {
    payment: {
      fk_headquarters: this.headquarters,
      deposit_to_be_redeemed: positive_balance,
      fk_payment_method,
      payment: payment
    },
    deposit: {
      student_id: this.student.idstudent,
      procedure_id: course.procedure_id,
      cash: cash > 0,
      transfer: transfer > 0,
      noncash: noncash > 0,
      credit: credit > 0,
      discount,
      total_cash: cash,
      total_noncash: noncash,
      total_transfer: transfer,
      total_credit: credit,
      totalchange: this.invoices_paid - this.invoices_payment
    }
  })
    .done(response => {
      toastr['success'](response.message);
      this.resetState();
      this.refreshHistory();
    })
    .fail(({ responseJSON: error }) => {
      try {
        Swal.fire({
          type: 'error',
          title: error.error,
          html: (`
            <ul>
              <li>${error.list.join('</li><li>')}</li>
            </ul>
          `)
        });
      } catch (e) {
        Swal.fire({
          type: 'error',
          title: 'Error inesperado',
          text: 'Por favor contacte a su administrador.'
        });
      }
    })
    .always(() => {
      this.loading = false;
    })
  ;
}
