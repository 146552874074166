$.fn.dropdown = require('semantic-ui-dropdown')
export default function( event ) {
  this.loading = true;
  const form = event.target;
  const form_data = new FormData( form );

  $.ajax(
    {
      url: form.action,
      type: 'post',
      data: form_data,
      timeout: 15000,
      processData: false,
      contentType: false
    }
  ).done(
    response => {
      if( response.status==='ok' ) {
        this.resetState();
        form.reset();
        $( '.selection.dropdown' ).dropdown( 'clear' );
        toastr['success']( response.message );
        this.refreshHistory();
      } else {
        toastr['error']( response.statusText );
      }
    }
  ).fail(
    ( { responseText: errorRaw } ) => {
      try {
        const error = JSON.parse( errorRaw );
        Swal.fire( {
          type: 'error',
          title: error.error,
          html:
            ( `
              <ul>
                <li>${ error.list.join( '</li><li>' ) }</li>
              </ul>
            ` )
        } );
      } catch(e) {
        Swal.fire( {
          type: 'error',
          title: 'Error inesperado',
          text: 'Por favor contacte a su administrador.'
        } );
      }

    } ).always( () => {
    this.loading = false;
  } );
}
