$ = require('jquery');
$.fn.transition = require('semantic-ui-transition');
$.fn.dimmer = require('semantic-ui-dimmer');
$.fn.modal = require('semantic-ui-modal');

export default function() {
  $.get( `/cash_registers/${ this.uid }/cashiers/${ this.crid }.json` )
    .done( response => {
      this.cash_register_stats.values = response.values;
      this.cash_register_stats.novelties = response.novelties;
    } );

  $( '.ui.modal' ).modal( 'show' );
}
