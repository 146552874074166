export default function() {
  return {
    cashierMode: 'first',
    selectedCourse: null,
    invoices: {
      payment_method: '',
      noncash: 0,
      cash: 0,
      credit: 0,
      payment: 0,
      transfer: 0,
      positive_balance: 0,
      discount: 0
    },
    uid: '',
    crid: '',
    headquarters: '',
    loading: false,
    procedure_name: '',
    last_transactions: [],
    subtotal: 0,
    discount: 0,
    discount_temporal: 0,
    posTerminalPay: 0,
    cashPay: 0,
    transferPay: 0,
    creditPay: 0,
    payMethod: '',
    agreement: '',
    agreement_discount: 0,
    noveltyValue: '',
    student: {
      idstudent: '',
      name: '',
      last_name: '',
      document_type: '',
      document: '',
      positive_balance: '',
      courses: []
    },
    student_discount: {
      iddeposit_discount: '',
      discount: '',
      agreement_id: '',
    },
    cash_register_stats: {
      values: {
        base_cash: 0,
        base_noncash: 0,
        base_transfer: 0,
        base_credit: 0,
        total_selling: 0,
        total_cash_outgoings: 0,
        transfers_in_cash_register: 0,
        credits_in_cash_register: 0,
        discounts: 0,
        cash_in_cash_register: 0,
        bouchers_in_cash_register: 0,
        incomings_novelty: 0,
        outgoings_novelty: 0
      },
      novelties: []
    }
  };
}
